/* STYLES FROM: https://github.com/YIZHUANG/react-multi-carousel/blob/master/src/assets/styles.css */

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
  padding-bottom: 12px;
}


.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  /* display: none; */
  justify-content: center;
}

.react-multi-carousel-item--active {
  display: flex;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }

  .react-multi-carousel-track {
    overflow: visible !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}