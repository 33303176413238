:root {
    font-family: "Helvetica Now Text";
    font-weight: normal;
    font-style: normal;
    --black-shade-6: #A6A4B7;
    --black-shade-5: #363848;
    --black-shade-4: #25242E;
    --black-shade-3: #1B1A22;
    --text-purple: #BCB6E8;
    --white: #FFFFFF;
    --black: #000000;
    --black-shade-2: #0E0D10;
    --las-vegas: linear-gradient(103.98deg, #FFAC4A 0%, #632CDA 68.83%, #6B53FF 100%);
    --los-angles: linear-gradient(103.98deg, rgba(159, 117, 249, 0.4) 0%, rgba(137, 82, 253, 0.4) 45.48%, rgba(99, 44, 218, 0.4) 100%);
    --explore: linear-gradient(103.98deg, rgba(255, 215, 74, 0.5) 0%, rgba(44, 166, 218, 0.5) 68.83%, rgba(11, 123, 158, 0.5) 100%);
    --white-gradient: linear-gradient(90.94deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.9) 29.31%, #FFFFFF 54.39%, rgba(255, 255, 255, 0.9) 75.57%, rgba(255, 255, 255, 0.2) 100%);
    --denmark: linear-gradient(103.98deg, #C1FC76 0%, #94FF40 46.61%, #76E121 100%);
    --cyber-green-primary: #94FF40;
    --cyber-blue-primary: #7C67FF;
    --swiz-grape-light: #9F75F9;
    --white-gradient-paper: rgba(255, 255, 255, 0.2);
    --blue-card: linear-gradient(103.98deg, #DDCDFF 0%, #8952FD 41.54%, #5024B0 67.71%, #330D86 100%);
    --card-border: rgba(229, 217, 255, 0.25);
    --blue-card-bg: rgba(14, 13, 16, 0.4);
}

* {
    font-family: 'Helvetica Now Text';
    font-weight: normal;
    font-style: normal;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0;
    margin: 0;
}

body {
    background: var(--black);
}

p {
    margin: 0;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 500;
    color: var(--white);
    cursor: text;
}

/* Hide the number input spinner */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the number input spinner in Firefox */
input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

input,
textarea,
select {
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
}

textarea {
    resize: none;
}

.checkbox {
    border: 2px solid var(--black-shade-6);
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.checkbox:checked {
    background-color: var(--cyber-blue-primary);
    border-color: var(--cyber-blue-primary);
}

input:disabled {
    background-color: var(--black-shade-2)
}

option {
    background-color: var(--black-shade-5);
}

svg {
    color: var(--white);
}

div>svg {
    color: inherit
}

p.heading {
    font-family: 'Futura';
}

/* section {
    margin-top: 120px;
    margin-bottom: 120px;
} */

a {
    text-decoration: none;
}

strong {
    font-weight: 700;
}

button {
    cursor: pointer;
    display: flex;
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
}

button:disabled,
.btn-disabled {
    background-color: var(--black-shade-5);
    cursor: not-allowed;
}

button:disabled>*,
.btn-disabled {
    color: var(--black-shade-6);
}

button>* {
    cursor: pointer;
}

table {
    border-collapse: separate;
    border-spacing: 0 16px;
}

/* Typography component */

.base10 {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
}

.base13 {
    font-size: 13px;
    line-height: 15.6px;
    font-weight: 500;
}

.base16 {
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 500;
}

.base20 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.base25 {
    font-size: 25px;
    line-height: 30px;
    font-weight: 500;
}

.base31 {
    font-size: 31px;
    line-height: 37.2px;
    font-weight: 500;
}

.base39 {
    font-size: 39px;
    line-height: 46.8px;
    font-weight: 500;
}

.base49 {
    font-size: 49px;
    line-height: 58.8px;
    font-weight: 500;
}

.base61 {
    font-size: 61px;
    line-height: 73.2px;
    font-weight: 500;
}

/* Stack component */
.stack {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

/* Divider component */
.divider {
    background-color: rgba(255, 255, 255, 0.30);
    height: 1px;
    flex-shrink: 0;
    margin: 2px 0;
}

.vertical-divider {
    background-color: rgba(255, 255, 255, 0.30);
    width: 1px;
    flex-shrink: 0;
    margin: 0 2px;
}

/* Container component */
/* generated by chat gpt */
.container {
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 600px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (min-width: 1200px) {
        max-width: 1200px;
    }
}

.container-right {
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    display: block;
    position: relative;

    @media (min-width: 1200px) {
        width: calc((100% - 1200px)/2 + 1200px);
    }
}

/* Grid component */
.grid-container {
    display: grid;
}

.grid-item {
    box-sizing: border-box;
    padding: 8px;
}

.xs12 {
    width: 100%;
}

.xs8 {
    width: 66.66%;
}

.xs6 {
    width: 50%;
}

.xs4 {
    width: 33.33%;
}

.xs3 {
    width: 25%
}

.grid-col-xs12 {
    grid-template-columns: repeat(1, 1fr);
}

.grid-col-xs4 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-col-xs3 {
    grid-template-columns: repeat(4, 1fr);
}

@media (min-width: 600px) {
    .sm12 {
        width: 100%;
    }

    .sm10 {
        width: 83.33%;
    }

    .sm9 {
        width: 75%;
    }

    .sm8 {
        width: 66.66%
    }

    .sm6 {
        width: 50%;
    }

    .sm4 {
        width: 33.33%;
    }

    .sm3 {
        width: 25%;
    }

    .sm2 {
        width: 16.66%;
    }

    .grid-col-sm6 {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid-col-sm3 {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 900px) {
    .md12 {
        width: 100%;
    }

    .md10 {
        width: 83.33%;
    }

    .md8 {
        width: 66.66%
    }

    .md7 {
        width: 58.33%
    }

    .md6 {
        width: 50%;
    }

    .md5 {
        width: 41.66%
    }

    .md4 {
        width: 33.33%;
    }

    .md3 {
        width: 25%;
    }

    .md2 {
        width: 16.66%
    }

    .grid-col-md6 {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid-col-md4 {
        grid-template-columns: repeat(3, 1fr);
    }

    .grid-col-md3 {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Avatar component */
.avatar {
    width: 40px;
    height: 40px;
    font-size: 12px;
    position: relative;
}

.avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.avatar-border {
    border-radius: 50%;
    border: 2px solid var(--white);
}

/* Accordion component */
.accordion {
    padding: 25px 32px;
}

.accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.accordion-content {
    display: none;
}

.accordion-content--visible {
    display: block;
}

/* Button component */
.btn {
    border-radius: 12px;
    border: none;
}

.btn16040 {
    width: 160px;
    height: 40px;
    border-radius: 12px;
    border: none;
}

.btn>* {
    padding-top: 14px;
    padding-bottom: 14px;
}

.btn-small>* {
    padding-top: 10px;
    padding-bottom: 10px;
}

.btn>*:first-child {
    padding-left: 20px;
}

.btn>*:last-child {
    padding-right: 20px;
}

.btn-border-cgp,
.border-cgp {
    border: 1px solid var(--cyber-green-primary)
}

.btn-border-cgp:disabled {
    border: 1px solid transparent;
}

.btn-border-cbp,
.border-cbp {
    border: 1px solid var(--cyber-blue-primary)
}

.btn-border-cbp:disabled {
    border: 1px solid transparent;
}

.btn-rounded,
.rounded {
    border-radius: 100px;
}

.btn-text {
    padding: 14px 20px;
    font-weight: 700;
}

.btn-text-input {
    padding: 7px 20px;
    font-weight: 700;
}

.btn.full,
.w-full {
    width: 100%;
}

.full>* {
    text-align: center;
    width: 100%;
}

.btn-cgp,
.bg-cgp {
    background: var(--cyber-green-primary);
}

.btn-cgp>* {
    color: var(--black-shade-2)
}

.btn-cbp,
.bg-cbp {
    background: var(--cyber-blue-primary);
}

/* Switch component */
/* Base styles */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    bottom: 0;
}

.switch-small {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 16px;
    bottom: 0;
}

.switch input,
.switch-small input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--white);
    transition: .4s;
    border-radius: 20px;
}

.slider-small {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--white);
    transition: .4s;
    border-radius: 16px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 1px;
    bottom: 1px;
    background-color: var(--cyber-blue-primary);
    transition: .4s;
    border-radius: 50%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.slider-small:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 1px;
    bottom: 1px;
    background-color: var(--cyber-blue-primary);
    transition: .4s;
    border-radius: 50%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

input:checked+.slider,
input:checked+.slider-small {
    background-color: var(--cyber-blue-primary);
}

input:checked+.slider:before {
    transform: translateX(40px);
    background-color: var(--white);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

input:checked+.slider-small:before {
    transform: translateX(16px);
    background-color: var(--white);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

/* Modal */
.modal-overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    position: relative;
    z-index: 100;
    background-color: var(--black-shade-2);
    backdrop-filter: blur(50px);
    border-radius: 24px;
    padding: 24px;
    max-width: 90%;
}

/* Styles for different breakpoints */
@media (max-width: 599px) {
    .switch {
        width: 30px;
        height: 20px;
    }

    .slider:before {
        height: 18px;
        width: 18px;
        left: 1px;
        bottom: 1px;
    }
}

@media (min-width: 600px) and (max-width: 959px) {
    .switch {
        width: 35px;
        height: 20px;
    }

    .slider:before {
        height: 18px;
        width: 18px;
        left: 1px;
        bottom: 1px;
    }
}

@media (min-width: 960px) {
    .switch {
        width: 64px;
        height: 24px;
    }

    .slider:before {
        height: 22px;
        width: 22px;
        left: 1px;
        bottom: 1px;
    }
}

/* Drawer */
@media (min-width: 900) {
    .drawer {
        width: 240px;
        display: block;
    }
}

@media (max-width: 900) {
    .drawer {
        width: 0;
        display: none;
    }
}

/* User Defined Gradient */
.gradient-heading {
    background: var(--white-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* user defined  custom classes */
.wt-light {
    font-weight: 400;
}

.wt-bold {
    font-weight: 700;
}

.pointer {
    cursor: pointer;
}

.pointer p {
    cursor: pointer;
}

.highlight:hover,
.white {
    color: var(--white);
}

.black {
    color: var(--black);
}

.hover-black-shade-6:hover {
    color: var(--black-shade-6);
}

.hover-bg-black-shade-3:hover {
    background-color: var(--black-shade-3);
}

.black-shade-6 {
    color: var(--black-shade-6);
}

.black-shade-5 {
    color: var(--black-shade-5);
}

.black-shade-3 {
    color: var(--black-shade-3);
}

.black-shade-2 {
    color: var(--black-shade-2);
}

.text-purple {
    color: var(--text-purple);
}

.color-sgl {
    color: var(--swiz-grape-light);
}

.color-denmark {
    /* color: var(--denmark); */
    background: var(--denmark);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.color-los-angles {
    /* color: var(--los-angles); */
    background: var(--los-angles);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.color-las-vegas {
    /* color: var(--los-angles); */
    background: var(--las-vegas);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header {
    position: relative;
    /* top: 0;   */
    width: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.7);
    border-bottom: 1px solid rgba(54, 56, 72, 0.3);
    backdrop-filter: blur(40px);
    /* margin-top: 16px;
    margin-bottom: 24px;

    @media (min-width: 600px) {
        margin-top: 32px;
        margin-bottom: 40px;
    } */
}

.xs-none {
    display: block;

    @media (max-width: 600px) {
        display: none;
    }
}

.xs-block {
    display: none;

    @media (max-width: 600px) {
        display: block;
    }
}

.sm-block {
    display: none;

    @media (max-width: 900px) {
        display: block;
    }
}

.sm-none {
    display: block;

    @media (max-width: 900px) {
        display: none;
    }
}

/* @media (min-width: 600) {
    .sm-none {
        display: block;
    }

    .sm-block {
        display: none;
    }
} */

@media (max-width: 900px) {
    .md-none {
        display: none;
    }

    .md-block {
        display: block;
    }
}

/* .sm-none {
    display: block;

    @media (max-width: 900px) {
        display: none;
    }
} */

.flex {
    display: flex;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
}

.nowrap {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.nowrap>* {
    flex: 0 0 auto;
}

.nowrap::-webkit-scrollbar {
    display: none;
}

.grid {
    display: grid;
}

.row {
    display: flex;
    flex-direction: row;
}

.row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.column {
    display: flex;
    flex-direction: column;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.justify-left {
    display: flex;
    justify-content: flex-start;
}

.align-center {
    display: flex;
    align-items: center;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.align-flexstart {
    display: flex;
    align-items: flex-start;
}

.align-flexend {
    display: flex;
    align-items: flex-end;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.footerbox {
    margin-bottom: 32px;
    margin-left: 0px;
    width: 320px;

    @media (min-width: 600) {
        margin-bottom: 0px;
        width: 100%
    }

    @media (min-width: 1200) {
        width: 300px;
    }
}

.flexgap {
    display: flex;
    gap: 16px;
}

.spacing1 {
    gap: 8px;
}

.spacing2 {
    gap: 16px;
}

.spacing20 {
    gap: 20px;
}

.spacing3 {
    gap: 24px;
}

.spacing4 {
    gap: 32px;
}

.spacing5 {
    gap: 40px;
}

.spacing6 {
    gap: 48px;
}

.spacing-row-1 {
    row-gap: 8px;
}

.spacing-row-2 {
    row-gap: 16px;
}

.spacing-row-3 {
    row-gap: 24px;
}

.spacing-col-1 {
    column-gap: 8px;
}

.spacing-col-2 {
    column-gap: 16px;
}

.spacing-col-3 {
    column-gap: 24px;
}

.las-vegas {
    background: var(--las-vegas);
}

.los-angles {
    background: var(--los-angles);
}

.explore {
    background: var(--explore);
}

.las-vegas-border {
    border: 1px solid transparent;
    border-image: var(--las-vegass) 1;
}

.denmark {
    background: var(--denmark);
}

.denmark>* {
    color: var(--black);
}

.color-las-vegas {
    color: var(--las-vegas);
}

.cyber-green-primary,
.cgp {
    color: var(--cyber-green-primary);
}

.cyber-blue-primary,
.cbp {
    color: var(--cyber-blue-primary);
}

.white-gradient-paper {
    background: var(--white-gradient-paper);
}

.bg-black-shade-2 {
    background: var(--black-shade-2);
}

.bg-black-shade-3 {
    background: var(--black-shade-3);
}

.bg-black-shade-4 {
    background: var(--black-shade-4);
}

.bg-black-shade-5 {
    background: var(--black-shade-5);
}

.bg-black {
    background: var(--black);
    overflow: hidden;
}

.bg-white {
    background: rgba(255, 255, 255, 0.1)
}

.bg-pure-white {
    background: rgba(255, 255, 255, 1);
}

.border-black-shade-6 {
    border-color: var(--black-shade-6);
    border: 1px solid var(--black-shade-6);
}

.border-4-bs6 {
    border-color: var(--black-shade-6);
    border: 4px solid var(--black-shade-6);
}

.border-wgp {
    border-color: var(--white-gradient-paper);
}

.border-black-shade-3 {
    border: 1px solid var(--black-shade-3);
}

.border-4-bs3 {
    border-color: var(--black-shade-3);
    border: 4px solid var(--black-shade-3);
}

.card {
    border-radius: 12px;
    background-color: var(--black-shade-2);
    overflow: hidden;
}

.black-card-no-border {
    background: rgba(14, 13, 16, 0.7);
    box-shadow: 0px 0px 10px rgba(27, 26, 34, 0.25);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    overflow: hidden;
}

.chip {
    padding: 8px 14px;
}

.bigchip {
    padding: 10px 24px;
}

.blue-card {
    border-radius: 12px;
    backdrop-filter: blur(30px);
    background: var(--blue-card);
    border: 6px solid var(--card-border);
    background: var(--blue-card-bg);
}

.black-card {
    background: rgba(0, 0, 0, 0.6);
    border: 2px solid rgba(144, 129, 255, 0.05);
    box-shadow: 0px 0px 10px rgba(27, 26, 34, 0.25);
    backdrop-filter: blur(20px);
    border-radius: 16px;
}

.first-and-last:last-child {
    margin-right: 24px;
}

.first-and-last:first-child {
    margin-left: 24px;
}

.bluebg {
    background: var(--blue-card);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25)
}

.blue-border {
    border: 6px solid var(--card-border);
}

.blue-card-bg {
    background: var(--blue-card-bg)
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: fit-content;
    margin: auto;
}

.absolute-center {
    /* text-align: center; */
    /* background-color: var(--white); */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: space-between;
    align-content: center;
}

.m-auto {
    margin: auto;
}

.my2 {
    margin-top: 16px;
    margin-bottom: 16px;
}

.my3 {
    margin-top: 24px;
    margin-bottom: 24px;
}

.mx4 {
    margin-left: 32px;
    margin-right: 32px;
}

.m4 {
    margin: 32px;
}

.mx2 {
    margin-left: 16px;
    margin-right: 16px;
}

.my10 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.mb0 {
    margin-bottom: 0px;
}

.mb1 {
    margin-bottom: 8px;
}

.mb2 {
    margin-bottom: 16px;
}

.mb3 {
    margin-bottom: 24px;
}

.mb4 {
    margin-bottom: 32px;
}

.mb5 {
    margin-bottom: 40px;
}

.mb6 {
    margin-bottom: 48px;
}

.mt1 {
    margin-top: 8px;
}

.mt2 {
    margin-top: 16px;
}

.mt4 {
    margin-top: 32px;
}

.ml1 {
    margin-left: 8px;
}

.mr1 {
    margin-right: 8px;
}

.mr2 {
    margin-right: 16px;
}

.ml2 {
    margin-left: 16px;
}

.ml3 {
    margin-left: 24px;
}

.ml4 {
    margin-left: 32px;
}

.p1 {
    padding: 8px
}

.p2 {
    padding: 16px;
}

.p3 {
    padding: 24px;
}

.p4 {
    padding: 32px;
}

.pl0 {
    padding-left: 0px !important;
}

.pl1 {
    padding-left: 8px;
}

.pl2 {
    padding-left: 16px;
}

.pr1 {
    padding-right: 8px;
}

.pl3 {
    padding-left: 24px;
}

.px1 {
    padding-right: 8px;
    padding-left: 8px;
}

.px2 {
    padding-right: 16px;
    padding-left: 16px;
}

.px3 {
    padding-right: 24px;
    padding-left: 24px;
}

.px4 {
    padding-right: 32px;
    padding-left: 32px;
}

.py2 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.py9 {
    padding-top: 72px;
    padding-bottom: 72px
}

.py10 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.py15 {
    padding-top: 120px;
    padding-bottom: 120px
}

.px9 {
    padding-left: 72px;
    padding-right: 72px;
}

.pb1 {
    padding-bottom: 8px;
}

.b-none {
    border: none !important;
}

.border-las-vegas {
    border-radius: 12px;
    background-image: var(--las-vegas);
    padding: 1px;
}

.border-denmark {
    border-color: var(--denmark);
    border: 1px solid var(--denmark);
}

.border-los-angles {
    border: 1px solid var(--los-angles);
}

.bb {
    border-bottom: 1px solid transparent;
}

.bt {
    border-top: 1px solid transparent;
}

.bl {
    border-left: 1px solid transparent;
}

.br {
    border-right: 1px solid transparent;
}

.border-white {
    border-color: var(--white);
}

.clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.clamp3 {
    -webkit-line-clamp: 3;
}

.clamp4 {
    -webkit-line-clamp: 4;
}

.clamp5 {
    -webkit-line-clamp: 5;
}

.image-hover {
    filter: grayscale(100%);
}

.image-hover:hover {
    filter: grayscale(0);
    transform: scale(1.05);
    transition: 0.3s ease-in-out;
}

.hidden {
    overflow: hidden;
}

.z1 {
    z-index: 1;
    overflow: hidden;
}

.cardborder {
    border: 1px solid transparent;
}

.cardborder:hover {
    border: 1px solid;
    border-color: white;
}

.scale:hover {
    transition: 0.3s ease-in-out;
    transform: scale(1.05);
}

.markdown>p,
.markdown>ul>li>* {
    color: inherit;
}

.markdown>ul {
    padding-left: 40px;
}

.markdown>ul>li {
    margin-bottom: 16px;
}

.r0 {
    border-radius: 0px;
}

.r12 {
    border-radius: 12px;
}

.r16 {
    border-radius: 16px;
}

.r1000 {
    border-radius: 1000px;
}


.menu {
    top: calc(100% + 16px);
    right: 0;
    width: 150px;
    list-style-type: none;
    border: 2px solid var(--black-shade-5);
    overflow: hidden;
    z-index: 1000;
}

.menu-item>* {
    color: var(--black-shade-6);
    cursor: pointer;
}

.menu-item:hover {
    background: var(--black-shade-5);
}

.menu-item:hover>* {
    color: var(--white);
}

.menu-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
}

.drawer {
    top: calc(100% + 16px);
    right: 0;
    width: 150px;
    list-style-type: none;
    border: 2px solid var(--black-shade-5);
    overflow: hidden;
    z-index: 1000;
}

.hover-white:hover {
    color: var(--white);
}

.input {
    padding: 8px 14px;
    border: 1px solid transparent;
    color: var(--black-shade-6);
    /* margin: 4px 0px; */
}

.input::placeholder {
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 500;
}

.input-big {
    padding: 14px 24px;
    border: 1px solid transparent;
    color: var(--black-shade-6);
    /* margin: 4px 0px; */
}

.input:focus,
.input:focus-visible,
.input:active,
.input:visited {
    outline: none;
    border: 1px solid var(--black-shade-5);
    color: var(--white);
    background-color: var(--black-shade-3);
}

.input::placeholder {
    color: var(--black-shade-6);
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
    background-color: var(--black-shade-3) !important;
    transition: 5000s ease-in-out 0s;
}

.input-button,
.input-end-props,
.input-end-text {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.input-start-props {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
}

.label {
    color: var(--black-shade-6);
    position: absolute;
    top: -10px;
    /* left: 16px; */
    transform: translateY(-50%);
}

.icon-button {
    cursor: pointer;
}

.icon-button.p0>* {
    padding: 0px
}

.icon-button>*:hover {
    border-radius: 100%;
    background-color: var(--black-shade-3);
}

.hoveredIconButton {
    border-radius: 100%;
    background-color: var(--black-shade-3);
    color: var(--white);
    font-size: 20px;
    padding: 8px;
    cursor: pointer;
}

.icon-button>* {
    color: var(--white);
    font-size: 20px;
    padding: 8px;
}

.tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    white-space: nowrap;
    cursor: pointer;
}

.tooltip-right {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    white-space: nowrap;
    cursor: pointer;
}

.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1.5s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.scrollable-area {
    position: relative;
    overflow: hidden;
}

.scrollable-content {
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.scrollable-content::-webkit-scrollbar {
    width: 0;
}

.selected-icon>svg {
    color: var(--cyber-blue-primary);
    font-size: 20px;
}

.icon>svg {
    color: var(--black-shade-6);
    font-size: 20px;
}

.errormsg {
    color: red
}